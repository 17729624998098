import { MotifButton } from '@ey-xd/motif-react';
import './index.scss';
import React from 'react';
import ReactGA from 'react-ga4';
import { SiteCookies } from '../../interfaces';

interface CookieConsentPropTypes {
  data: SiteCookies;
  onClick: () => void;
}

const TRACKING_ID = window.location.hostname === 'eoy-directory.ey.com' ? 'G-8WKB0JWRJX' : 'G-PTQCNPFCCN';

const CookieConsentComponent: React.FC<CookieConsentPropTypes> = ({ data, onClick }) => {
  const cookieFooter = document.getElementById('cookies');
  const cookiesAccepted = () => localStorage.cookieAccepted && localStorage.cookieAccepted.toString() === 'true';
  const acceptCookies = () => {
    localStorage.acceptGoogleAnalytics = true;
    localStorage.acceptGoogleAdwords = true;
    localStorage.cookieAccepted = true;
    ReactGA.initialize(TRACKING_ID);

    if (cookieFooter) {
      cookieFooter.classList.remove('eoy-state-visible');
    }
  };

  return (
    <>
      <div className={`eoy-cookie-notification ${!cookiesAccepted() && `eoy-state-visible`}`} id="cookies">
        <div className="eoy-cookie-notification-inner">
          <div className="eoy-cookie-message">
            <div className="eoy-cookie-description eoy-body">
              <h2 className="eoy-cookie-header eoy-body">{data.footer.header}</h2>
              <div>
                This site uses cookies to provide you with a personalized browsing
                experience and allows us to understand more about you.&nbsp;
                <a
                  href="https://www.ey.com/en_us/cookie-policy"
                  target="_blank"
                  rel="noopener noreferrer">More information on the cookies we use
                </a>
                .&nbsp;By clicking &apos;Yes, I accept&apos; you agree and consent to our use of cookies.
                More information on what these cookies are and how we use them, including how you can manage them, is outlined in our&nbsp;
                <a
                  href="https://www.ey.com/en_us/privacy-statement"
                  target="_blank"
                  rel="noopener noreferrer">
                  Privacy Notice
                </a>
                .&nbsp;Please note that your decision to decline the use of cookies is limited to this site only,
                and not in relation to other EY sites or ey.com.
                Please refer to the privacy notice/policy on these sites for more information.
              </div>
            </div>
          </div>

          <div className="eoy-cookie-notification-link">
            <MotifButton
              size="large"
              tabIndex={0}
              onClick={acceptCookies}>
              {data.footer.accept}
            </MotifButton>
            <MotifButton
              size="large"
              onClick={onClick}
              className="eoy-footer-button" data-testid="cookie-footer-button">{data.footer.customize}</MotifButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default CookieConsentComponent;
